<template>
    <div data-app>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
                         <h3>{{ cardTitle }}</h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          :label="$t('FORMS.search')"
                          single-line
                          hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="newsAuthorCollection"
                    :search="search"
                    :sort-by="['id']"
                    sort-desc="true"
                    :loading="loadingTable"
                    :footer-props="{
                        'items-per-page-options': [50, 100, 200],
                    }"
                    @click:row="handleClickItem"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <NewsAuthorModalForm
                              :modalData="modalData"
                              :permissions="permissions"
                              :endPoint="endPoint"
                              @closeModalForm="handleCloseModalForm"
                              @saveModalForm="handleSaveModalForm"
                              @new="handleNew"
                            ></NewsAuthorModalForm>
                            <v-spacer></v-spacer>
                            <DeleteModalDialog
                              :dialogDelete="dialogDelete"
                              @closeDelete="handleCloseDelete"
                              @deleteItemConfirm="handleDeleteItemConfirm"
                            >
                            </DeleteModalDialog>
                        </v-toolbar>
                    </template>    
                    
                    <template v-slot:item.actions="{ item }">
                        <v-icon
                          color="primary"
                          v-if="permissionCan('delete') && item.deletable"
                          small
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                    </template>
                    
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SiteService from "@/core/services/site.service.js";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import NewsAuthorModalForm, { initialFormData } from "./NewsAuthorModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
export const PERMISSION_TO = "news.author.";
export default {
    name: "NewsAuthors",
    components: { NewsAuthorModalForm, DeleteModalDialog },
    mixins: [listModelsMixins],
    data() {
      return {
        cardTitle: this.$t("MENU.newsAuthors"),
        search: "",
        routePath: "/cms/news/authors/",
        permissionTo: PERMISSION_TO,
        permissions: JSON.parse(localStorage.permissions),
        headers: [
          {
            text: this.$t("TABLE.name"),
            value: "name",
          },
          { text: "Actions", value: "actions", sortable: false, width: "100px" },
        ],

        modalData: {
            dialog: false,
            editedId: null,
        },
        editedItem: Object.assign({}, initialFormData()),
        dialogDelete: false,
        };
    },

    computed: {
      ...mapGetters(["newsAuthorCollection"]),

      endPoint() {
        return "news/admin/" + SiteService.getActiveSiteId() + "/news-author/";
      },
    },
    
    watch: {
        dialogDelete(val) {
          val || this.handleCloseDelete();
        },
        "$route.params.id": {
          handler: function (id) {
            if (id) {
              this.editItem(id);
            }
          },
          deep: true,
          immediate: true,
        },
    },

   
    methods: {
        ...mapActions(["fetchNewsAuthor"]),
        fetchModel() {
              return this.fetchNewsAuthor();
        },
    },

    mounted() {
      this.loadingTable = true;
      this.fetchModel().then(() => (this.loadingTable = false));
    },
};
</script>